
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'Futura PT';
  src: url('~@assets/fonts/FuturaPT/FuturaPTBook.woff2') format('woff2'),
    url('~@assets/fonts/FuturaPT/FuturaPTBook.woff') format('woff');
}
@font-face {
  font-family: 'Futura PT Bold';
  src: url('~@assets/fonts/FuturaPT/FuturaPTBold.woff2') format('woff2'),
    url('~@assets/fonts/FuturaPT/FuturaPTBold.woff') format('woff');
}

@font-face {
  font-family: 'Futura Cond PT';
  src: url('~@assets/fonts/FuturaPT/FuturaCondPT.woff2') format('woff2'),
    url('~@assets/fonts/FuturaPT/FuturaCondPT.woff') format('woff');
}


// Bubs Theme styles
@import '../colors';
@import '../mixins';

.theme--Bubs {
  &.application {
    background-color: white;
    @extend .poppins--font;
  }
  ::selection {
    color: white;
    background-color: darken($color-bubs-purple,3);
  }

  h1 {
    @extend .futura-bold--font;

    @include font-size(74px);

    line-height: 1.2;
    font-weight:700;

  }
  h2 {
    @extend .futura-bold--font;

    @include font-size(36px);

    margin-bottom: 0.5em;
    line-height: 1.2;
  }
  h3 {
    @extend .poppins--font;

    margin-bottom: 0.5em;
    font-weight: 500;
    line-height: 1.2;
  }
  .header-4 {
    @extend .futura-bold--font;

    @include font-size(74px);

    line-height: 0.95 !important;
  }

  .header-3 {
    @extend .poppins--font;

    @include font-size(62px);

    line-height: 1.1 !important;
  }

  .header-2 {
    @extend .futura-bold--font;

    @include font-size(54px, 0.7, 0.75, 0.85);

    line-height: 1.2 !important;
  }

  .header-1 {
    @extend .poppins--font;

    @include font-size(36px, 0.8, 0.8, 0.8);

    line-height: 1.1 !important;
    font-weight:700;
  }

  .hero-text {
    @extend .poppins--font;

    @include font-size(20px, 0.8, 0.8, 0.8);

    line-height: 1.7 !important;
  }

  .r-headline {
    @extend .poppins--font;

    @include font-size(28px);

    line-height: 1.4 !important;

  }

  .r-title {
    @extend .poppins--font;

    @include font-size(20px, 0.8, 0.8, 0.8);

    line-height: 1.4 !important;
  }

  .btn-base:not(.pa-0):not(.v-btn--icon):not(.v-btn--floating):not(.footer-nav-btn):not(.ui) {
    @extend .poppins--font;
    font-weight: bold !important;
    letter-spacing:0.03;

    @include btn-size(1rem, 1.1rem);

    &.v-btn--small {
      @include btn-size(0.7rem, 0.7rem);

      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &.v-btn--large {
      @extend .futura-cond--font;
      font-style:italic;
      @include btn-size(1.7rem);

      line-height: 1.4;
      letter-spacing: 0.043em;
    }
  }
} // .theme--Bubs
